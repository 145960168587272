import React from "react";
import PropTypes from "prop-types";
import BEMHelper from "react-bem-helper";
import { Link } from "gatsby";
import "./Modal.scss";

const classNames = new BEMHelper("modal");

const DefaultPage = ({ closeTo, children }) => (
  <main {...classNames()}>
    <Link to={closeTo} {...classNames("close-link")} />
    <div {...classNames("content")}>{children}</div>
  </main>
);

DefaultPage.propTypes = {
  children: PropTypes.node.isRequired,
  closeTo: PropTypes.string,
};

export default DefaultPage;
